<template>
	<div>
		<div class="ser-form">
			<Form ref="form" :model="search" inline>
				<FormItem>
					<Input v-model="search.name" placeholder="厂家名称" />
				</FormItem>
				<FormItem>
					<Button type="primary" icon="md-search" @click="init">搜索</Button>
				</FormItem>
				<FormItem>
					<Button type="primary" icon="md-add" @click="open_openModel()">新增厂家</Button>
				</FormItem>
				<!-- <FormItem>
					<Button type="error" @click="open_del(0)">批量删除</Button>
				</FormItem> -->
			</Form>
		</div>
		<div class="table-list">
			<Table :loading="loading" :columns="columns" :data="data">
				<template slot-scope="{row}" slot="status">
					<Tag :color="row.status ? 'success' : 'error'">{{ row.status ? '显示' : '隐藏' }}</Tag>
				</template>
				<template slot-scope="{row}" slot="action">
					<!-- <Button style="margin-right: 10px;" @click="open_del(row.id)">删除</Button> -->
					<Button type="primary" @click="open_openModel(row)">编辑</Button>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" :total="search.total" :current="search.page" :page-size="search.pageSize" :page-size-opts="[15,30,50]" @on-change="changePage" @on-page-size-change="changePageSize" show-total show-sizer></Page>
		</div>
		<Modal v-model="openModel" title="添加厂家">
			<Form :label-width="100">
				<FormItem label="厂家名称：">
					<Input v-model="addData.name" placeholder="厂家名称" />
				</FormItem>
				<FormItem label="生产类型：">
					<Select v-model="addData.device_type" multiple>
						<Option v-for="(item,index) in type" :key="index" :value="item.value">{{ item.label }}</Option>
					</Select>
				</FormItem>
				<FormItem label="是否显示：">
					<RadioGroup v-model="addData.status">
						<Radio :label="1">显示</Radio>
						<Radio :label="0">隐藏</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
			<template slot="footer">
				<Button @click="openModel = false" style="margin-right: 10px;">取消</Button>
				<Button @click="add_Model" type="primary">确认</Button>
			</template>
		</Modal>

		<!-- <Modal v-model="del_modal" title="提示" @on-ok="del_type" @on-cancel="del_modal = false">
			确认删除选中厂家吗？
		</Modal> -->
	</div>
</template>

<script>
	var add_data = {
		id: null,
		name: '', // 厂家名称
		device_type: [], // 生产类型
		status: 1, // 状态
	};
	export default {
		name: "DeviceBrand",
		data() {
			return {
				loading: false,
				openModel: false,
				// del_modal:false,
				search: {
					name: '',
					page: 1,
					pageSize: 15,
					total: 0
				},
				addData: JSON.parse(JSON.stringify(add_data)),
				columns: [
					// { type: 'selection', width: 60 },
					{ title: 'ID', key: 'id', minWidth: 80, tree: true },
					// {title: '图标', key: 'icon', minWidth: 70},
					{ title: '厂家名称', key: 'name', minWidth: 100 },
					{ title: '状态', slot: 'status', minWidth: 80 },
					{ title: '添加时间', key: 'create_date', minWidth: 160 },
					{ title: '设备类型', key: 'type', minWidth: 70 },
					{ title: '操作', slot: 'action', minWidth: 170 }
				],
				data: [],
				type: [],
				id:null,
			}
		},
		created() {
			this.init();
		},
		methods: {
			init() {
				var _this = this;
				this.requestApi('/adm/get_brand_list', this.search).then(function(res) {
					if (res.status) {
						_this.data = res.data.data;
						_this.search.total = res.data.total;
						_this.type = res.type
					}
				})
			},
			open_openModel(data = null) {
				if (data) {
					for(let index in this.addData){
						this.addData[index] = data[index];
					}
				} else {
					this.addData = JSON.parse(JSON.stringify(add_data));
				}
				this.openModel = true
			},
			//添加厂家
			add_Model() {
				if (!this.addData.name) return this.alertErr('设备名称不能为空！')
				if (!this.addData.device_type || this.addData.device_type.length < 1) return this.alertErr('设备分类不能为空！')
				let _this = this
				this.requestApi('/adm/add_type', {
					data: this.addData
				}).then(function(res) {
					if (res.status == 200) {
						_this.alertSucc(res.msg)
						_this.openModel = false
						_this.init()
					} else {
						_this.alertErr(res.msg)
					}
				})
			},
			open_del(id){
				var _this = this;
				this.$Modal.confirm({
					title: '删除厂家',
					content: '确认要删除该厂家吗？',
					onOk: () => {
						_this.requestApi('/adm/del_type', { id: id }).then((res) => {
							if(res.status == 200 ){
								_this.alertSucc('删除成功');
								_this.init();
							} else {
								_this.alertErr(res.msg);
							}
						});
					}
				});
			},
			changePage(page){
				this.search.page = page;
				this.init();
			},
			changePageSize(size){
				this.search.page = 1;
				this.search.pageSize = size;
				this.init();
			},
			// // 删除厂家
			// del_type() {
			// 	var _this = this
			// 	this.requestApi('/adm/del_type', {
			// 		id: this.id
			// 	}).then(function(res) {
			// 		if(res.status == 200 ){
			// 			_this.alertSucc(res.msg)
			// 			_this.id = null
			// 			_this.init()
			// 		}else {
			// 			_this.alertErr('删除失败')
			// 		}
			// 	})
			// },
			// select_del(e){
			// 	var arr = []
			// 	for(var q in e){
			// 		arr.push(e[q].id)
			// 	}
			// 	this.id = arr
			// }
		}
	}
</script>

<style scoped>
	.push-button {
		text-align: right;
	}
</style>
